/* ======= BOOTSTRAP VARIABLES, IMPORT & FONTS ======= */

$enable-shadows: true;
$font-family-sans-serif: 'Open Sans', sans-serif, 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$headings-font-family: 'Roboto', sans-serif;
$body-color: #777;
$headings-color: #1A1A1A;
$primary: #D1181F;
$secondary: #2699FB;
$white: #FFF;
$grey: #F8F4F4;
$dark-grey: #E6E6E6;
$theme-colors: ();
$theme-colors: map-merge((
	"body-color": $body-color,
	"white": $white,
	"grey": $grey,
	"dark-grey": $dark-grey
), $theme-colors);
$input-btn-padding-y: .5rem;
$input-btn-padding-x: 2rem;
$navbar-brand-height: 5rem;
$navbar-light-color: $headings-color;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-nav-link-padding-x: 1.5rem;
$dropdown-item-padding-y: .5rem;
$hr-border-color: #E6E6E6;
$badge-pill-padding-x: 1rem;
$badge-font-weight: 400;
$label-margin-bottom: .75rem;


@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i');



/* ======= IMPORT ======= */

@import '../../node_modules/bootstrap/scss/functions';
@import 'bootstrap-variables';
@import 'font-awesome-variables';

@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/font-awesome/scss/font-awesome';
@import '../../node_modules/owl.carousel/src/scss/owl.carousel';
@import '../../node_modules/owl.carousel/src/scss/owl.theme.default';


 
/* ======= UTILS ======= */

.no-ws {
	white-space: normal;
}

.no-ww {
	word-wrap: normal;
}

/* ======= LAYOUT ======= */

.container-fluid {
	max-width: 1600px;
	&.no-mw {
		max-width: none;
	}
}

.intro-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	&-red {
		background-color: rgba(209, 24, 31, .85);
	}
	&-dark {
		background-color: rgba(26, 26, 26, .75);
	}
	&-ultra-dark {
		background-color: rgba(26, 26, 26, .9);
	}
	&-image {
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		margin: 0;
		padding: 0;
		min-height: 15rem;
	}
}

.bg-cover {
	@include media-breakpoint-up(md) {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		img {
			@include media-breakpoint-up(md) {
				display: none;
			}
		}
	}
}

.bg-poly {
	&-light {
		background-image: url(../img/bg.jpg);
		background-size: cover;
		background-position: 50% 50%;
	}
	&-dark {
		background-image: url(../img/bg-dark.jpg);
		background-size: cover;
		background-position: 50% 50%;
	}
	&-ultra-dark {
		background-image: url(../img/bg-ultra-dark.jpg);
		background-size: cover;
		background-position: 50% 100%;
	}
}

#contacts-map {
    margin-bottom: -.4rem;
}

#single-service-more {
	h2 {
		font-size: 2.25rem;
		font-weight: 500;
		color: #FFF;
		margin-bottom: 2rem;
		@include media-breakpoint-up(md) {
			font-size: 2.75rem;
		}
	}
	#single-service-details {
		position: relative;
		margin: 0 auto;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 2.5rem;
			height: 100%;
			width: .35rem;
			background: #FFF;
		}
		@include media-breakpoint-up(lg) {
			max-width: 1600px;
			padding: 0 3em;
			&::before {
				left: 50%;
				margin-left: -2px;
			}
		}
		.single-service-details-block {
			position: relative;
			margin: 2em 0;
			word-wrap: break-word;
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
			@include media-breakpoint-up(lg) {
				margin: 0 0 -3rem 0;
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
				&:nth-child(even) .single-service-details-content {
					float: right;
					&::before {
						top: 24px;
						left: auto;
						right: 100%;
						border-color: transparent;
						border-right-color: #FFF;
					}
				}
			}
			.single-service-details-number {
				position: absolute;
				top: 0;
				left: .75rem;
				width: 3.75rem;
				height: 3.75rem;
				border-radius: 50%;
				background-color: #FFF;
				text-align: center;
				color: $primary;
				@include media-breakpoint-up(lg) {
					width: 5rem;
					height: 5rem;
					left: 50%;
					margin-left: -2.5rem;
				}
				span.single-service-number {
					text-align: center;
					position: absolute;
					top: .65rem;
					left: 1.45rem;
					margin: 0 auto;
					font-size: 1.625rem;
					font-weight: 900;
					@include media-breakpoint-up(lg) {
						font-size: 2.5rem;
						left: 1.75rem;
					}
				}
			}
			.single-service-details-content {
				position: relative;
				margin: 0 3rem 0 6rem;
				background: #FFF;
				padding: 2rem;
				&::before {
					content: '';
					position: absolute;
					top: 1rem;
					right: 100%;
					height: 0;
					width: 0;
					border: 1rem solid transparent;
					border-right: 1rem solid #FFF;
				}		
				h3, p {
					margin: 0;
				}
				h3 {
					margin-bottom: 1rem;
				}
				@include media-breakpoint-up(lg) {
					margin: 0;
					padding: 1.6rem;
					width: 40%;
					&::before {
						top: 2rem;
						left: 100%;
						border-color: transparent;
						border-left-color: #FFF;
					}
				}
			}
		}
	}
}

#service-related,
#solution-related {
	a {
		color: $body-color;
		&:hover {
			color: $secondary;
			transition: color .15s linear;
			text-decoration: none;
		}
		span {
			display: block;
			font-size: 1rem;
			font-weight: 400;
			text-transform: uppercase;
			@include media-breakpoint-up(md) {
				font-size: 1.35rem;
			}
			@include media-breakpoint-up(xl) {
				font-size: 1.625rem;
			}
		}
	}
	img {
		&:hover {
			opacity: .5;
			transition: opacity .15s linear;
		}
	}
}


/* ======= HEADER ======= */

header {
	font-family: $headings-font-family;
	.navbar-brand {
		max-width: 50%;
		img {
			width: 15.5rem;
		}
	}
	.navbar-light {
		background-color: rgba(230, 230, 230, .95);
		.navbar-toggler {
			border-color: transparent;
			&:focus {
				outline: 0 none;
			}
		}
		.navbar-nav {
			.nav-item {
				.dropdown-menu {
					border: 0 none;
					border-radius: 0;
					background-color: inherit;
					box-shadow: none;
					@include media-breakpoint-up(lg) {
						background-color: rgba(230, 230, 230, .95);
						box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .175);					
					}
					.dropdown-divider {
						border-color: #888;
						margin: 0.5rem 1.5rem;
					}
					.dropdown-item {
						&.active,
						&:active {
							background-color: transparent;
							color: $primary;
						}
					}
				}
				.nav-link {
					font-family: $headings-font-family;
					text-transform: uppercase;
					@include media-breakpoint-up(md) {
						padding-right: .65rem;
						padding-left: .65rem;
					}
					@include media-breakpoint-only(lg) {
						font-size: .9rem;
					}
					@include media-breakpoint-up(xl) {
						padding-right: 1rem;
						padding-left: 1rem;
					}
				}
			}
		}
	}	
	#nav-magazine-toggler {
		display: block;
		width: 100%;
		@include media-breakpoint-up(md) {
			display: none;
		}
		&:focus,
		.focus {
			box-shadow: none;
		}
	}
	#nav-magazine {
		@include media-breakpoint-up(md) {
			display: block !important;
		}
		ul {
			padding-left: 0;
			list-style: none;
			li {
				padding-top: .25rem;
				padding-bottom: .25rem;
				@include media-breakpoint-up(md) {
					display: inline-block;						
				}
				a {
					color: #FFF;
					text-transform: uppercase;
					font-size: 1.25rem;
					&.hover,
					&:hover {
						text-decoration: none;
					}			
					@include media-breakpoint-up(md) {
						padding-right: 1.5rem;
						padding-left: 1.5rem;
						position: relative;						
					}
				}
				&.active,
				&:hover {
					a {
						@include media-breakpoint-up(md) {
							&:after {
								width: 0; 
								height: 0;  
								border-left: 8px solid transparent; 
								border-right: 8px solid transparent; 
								border-bottom: 8px solid #FFF; 
								display: block; 
								position: absolute; 
								bottom: -1rem; 
								content: ''; 
								left: 50%;
							}
						}
					}
				}
			}
		}
	}
}


/* ======= TYPO ======= */

body {
	@include media-breakpoint-up(md) {
		font-size: 1.075rem;
	}
	@include media-breakpoint-up(xl) {
		font-size: 1.125rem;
	}
}

.h1,
h1 {
	font-size: 2.25rem;
	text-transform: uppercase;
	@include media-breakpoint-up(md) {
		font-size: 2.5rem;
	}
	@include media-breakpoint-up(xl) {
		font-size: 2.75rem;
	}
}

h2,
.h2 {
	font-size: 1.5rem;
	font-weight: 300;
	color: #666;
	@include media-breakpoint-up(md) {
		font-size: 1.875rem;
	}
	@include media-breakpoint-up(xl) {
		font-size: 2.25rem;
	}
}

h3,
.h3 {
	font-size: 1.25rem;
	font-weight: 400;
	color: $primary;
	@include media-breakpoint-up(md) {
		font-size: 1.45rem;
	}
	@include media-breakpoint-up(xl) {
		font-size: 1.625rem;
	}
}

h4,
.h4 {
	font-size: 1.125rem;
	font-weight: 700;
    @include media-breakpoint-up(md) {
    	font-size: 1.25rem;
    }
    @include media-breakpoint-up(xl) {
    	font-size: 1.375rem;
    }
}

h5,
.h5 {
	font-size: 1.125rem;
	font-weight: 400;
    text-transform: uppercase;
    @include media-breakpoint-up(md) {
    	font-size: 1.25rem;
    }
    @include media-breakpoint-up(xl) {
    	font-size: 1.375rem;
    }
}

h6,
.h6 {
	font-size: 1rem;
	font-weight: 400;
    text-transform: uppercase;
    @include media-breakpoint-up(md) {
    	font-size: 1.1rem;
    }
    @include media-breakpoint-up(xl) {
    	font-size: 1.125rem;
    }
}

a {
	transition: .15s linear;
}

blockquote,
.blockquote {
	border-left: 4px solid $primary;
	padding-left: 1.5rem;
	h2 {
		color: $primary;
	}
}

ul.darrow {
	list-style: none;
	padding: 0;
	li {
		padding-left: 1.25em;
		font-size: .875rem;
		&:before {
			content: "\f101";
			font-family: FontAwesome;
			display: inline-block;
			margin-left: -1.25em;
			width: 1.25em; 
		}
	}
}

#social-link {
	.icon-facebook {
		color: #3b5998;
		&:hover {
			color: darken(#3b5998, 15%);
		}
	}
	.icon-linkedin {
		color: #007bb6;
		&:hover {
			color: darken(#007bb6, 15%);
		}	
	}

	.icon-twitter {
		color: #00b6f1;
		&:hover {
			color: darken(#00b6f1, 15%);
		}
	}
	.icon-google-plus {
		color: #df4a32;
		&:hover {
			color: darken(#df4a32, 15%);
		}
	}
}

/* ======= BUTTONS ======= */

.btn {
	font-family: $headings-font-family;
	text-transform: uppercase;
	&.btn-outline-white {
		font-weight: 500;
		border-width: 2px;
		&:hover,
		&:active,
		&:focus {
			color: $primary;
		}
	}
	&.btn-whiterb {
		@include button-variant(#FFF, $primary, darken($primary, 7.5%));
		color: $primary;
	}
}


/* ======= CAROUSEL ======= */

.owl-theme .owl-dots .owl-dot {
	span {
		background: $body-color;
	}
	&.active {
		span {
			background: $primary;
		}
	}
}

#solutions-carousel,
#clients-carousel {
	.item-owlslide {
		img {
			&:hover {
				opacity: .3;
				transition: opacity .15s linear;
			}
		}
	}
}


/* ======= FOOTER ======= */

footer {
	background-color: #E6E6E6;
	hr {
		box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
		@include media-breakpoint-up(lg) {
			margin-top: 0;
		}
	}
	#social-contact {
		margin-bottom: 2rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			flex-wrap: wrap;
		}
		a {
			color: $body-color;
			font-size: 0.875rem;
			@include media-breakpoint-up(lg) {
				font-size: 1rem;
			}
			&:hover {
				color: $secondary;
				text-decoration: none;
			}
			i.fa {
				font-size: 3rem;
				@include media-breakpoint-up(lg) {
					font-size: 1rem;
				}
			}
		}
		> div {
			@media (min-width: 992px) and (max-width: 1400px) {
				width: 50%;
			}
		}
	}
}

/* ======= CTA FULLWIDTH ======= */

.full-cta {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	background-color: $primary;
	p {
		text-transform: uppercase;
		font-size: 1.625rem;
		color: #FFF;
		margin-bottom: 1rem;
		@include media-breakpoint-up(md) {
			margin-right: 3rem;
			margin-bottom: 0;
		}
	}
}


/* ======= VIDEO ======= */

.video-container {
	video {
		width: 100%;
		height: auto;
		max-height: 100%;
		display: block;
	}
	video[poster] {
		object-fit: cover;
	}
	.play-container {
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		transform: translate(-50%, -50%);
		z-index: 99;
		img {
			@include media-breakpoint-down(sm) {
				max-width: 100px;
				height: auto;
			}
			&:hover {
				cursor: pointer;
				opacity: 0.7;
			}
		}
	}
}


/* ======= PORTFOLIO ======= */

#single-portfolio-evidence {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.success-item {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #1E1E1E;
	overflow: hidden;
	float: left;
	position: relative;
	width: 50%;
	padding-bottom: 50%;
	transition: .15s linear;
	&:hover .oversuccess {
		display: block;
	}
	&:hover .content {
		display: block;
	}
	@include media-breakpoint-up(lg) {
		width: 25%;
		padding-bottom: 25%;
	}
	#portfolio-carousel & {
		width: 100%;
		padding-bottom: 100%;
	}
	.oversuccess {
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		background: rgba(0, 0, 0, 0.7);
		@include media-breakpoint-up(md) {
			display: none;   		
		}
	}
	.content {
		display: block;
		text-align: center;
		position: absolute;
		height: 80%;
		width: 80%;
		padding: 5%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 5px solid #FFF;
		@include media-breakpoint-up(md) {
			display: none; 
		}
		.table {
			display: table;
			width: 100%;
			height: 100%;
			.table-cell {
				display: table-cell;
				vertical-align: middle;
				img {
					max-height: 50%;
					width: auto;
					max-width: 100%;
					margin: 0 auto;
				}
				a.btn-whiterb {
					margin-top: 2rem;
				}
			}
		}
	}
}



/* ======= MAGAZINE ======= */

#magazine-featured {
	.featured-single-magazine {
		width: 100%;
		height: auto;
		padding: 0;
		margin: 0 auto;
		overflow: hidden;
		position: relative;
		@include media-breakpoint-up(md) {
			margin: 0;
		}
		.magazine-post {
			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}
		}
	}
	#magazine-featured-container {
		> .featured-single-magazine {
			height: auto;
			@include media-breakpoint-up(md) {
				width: 60%;
				height: 40rem;
			}
			.magazine-post-image {
				@include media-breakpoint-up(md) {
					height: 26rem;
				}
				@include media-breakpoint-up(lg) {
					height: 40rem;
				}
			}
			.magazine-post-title {
				@include media-breakpoint-up(md) {
					left: 4rem;
					right: 4rem;
				}
				// @include media-breakpoint-up(lg) {
				// 	left: 7rem;
				// 	right: 7rem;
				// }
				h3 {
					a {
						@include media-breakpoint-up(md) {
							font-weight: 500;
							font-size: 2rem;
						}
						// @include media-breakpoint-up(lg) {
						// 	font-size: 3rem;
						// }
					}
				}
			}
		}
		> .flex-container-nested {
			display: block;
			width: 100%;
			@include media-breakpoint-up(md) {
				display: flex;
				flex-direction: column;
				width: 40%;
			}
			> .featured-single-magazine {
				height: auto;
				@include media-breakpoint-up(md) {
					width: 100%;
					height: 13rem;
				}
				@include media-breakpoint-up(lg) {
					height: 20rem;
				}
				.magazine-post-image {
					@include media-breakpoint-up(md) {
						height: 13rem;
					}
					@include media-breakpoint-up(lg) {
						height: 20rem;
					}
				}
				.magazine-post-title {
					@include media-breakpoint-up(md) {
						left: 3rem;
						right: 3rem;
					}
					// @include media-breakpoint-up(lg) {
					// 	left: 4rem;
					// 	right: 4rem;
					// }
					h3 {
						a {
							@include media-breakpoint-up(lg) {
								font-size: 1.5rem;
							}
							// @include media-breakpoint-up(lg) {
							// 	font-size: 1.875rem;
							// }
						}
					}
				}
			}
		}
	}
}

.magazine-post {
	position: relative;
	overflow: hidden;
	margin-bottom: 2rem;
	.intro-overlay {
		opacity: .3;
	}
	&.hover {
		.intro-overlay {
			opacity: 1;
			transition: opacity .15s linear;
		}
	}
	.magazine-post-image {
		position: relative;
		text-align: center;
		height: 12rem;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		@include media-breakpoint-up(md) {
			height: 13.5rem;
		}
		@include media-breakpoint-up(lg) {
			height: 20rem;
		}
	}
	.magazine-post-title {
		@include media-breakpoint-up(md) {
			position: absolute;
			left: 2rem;
			right: 2rem;
			top: 50%;
			transform: translateY(-50%);
		}
		h3 {
			line-height: 1.1;
			a {
				font-size: 1rem;
				color: $headings-color;
				&:hover,
				.hover {
					text-decoration: none;
					color: $primary;
				}
				@include media-breakpoint-up(md) {
					font-size: 1.25rem;
					color: $white;
					text-shadow: 1px 1px 2px #666;
					&:hover,
					.hover {
						color: darken(#FFFFFF, 15%);
					}
				}
			}
		}
	}
}

#magazine-single {
	h1 {
		color: $primary;
		text-transform: none;
	}
	#magazine-single-meta-container {
		flex-direction: column;
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			align-items: center;
		}
		.magazine-single-meta, .magazine-single-share {		
			font-size: .75rem;
			@include media-breakpoint-up(lg) {
				font-size: 1rem;
				flex-direction: row;
			}
		}
		.magazine-single-share {
			a {
				color: $body-color;
				&:hover {
					color: $secondary;
					text-decoration: none;
				}
			}
			@include media-breakpoint-up(lg) {
				margin-left: auto;
			}
		}
	}
	.tags {
		.btn {
			margin-bottom: 1rem;
			margin-right: 1rem;
		}
	}
}




/* ======= PAGINATION ======= */

.pagination {
	.page-item {
		&:first-child, &:last-child {
			.page-link {
				border-radius: 0;
			}
		}
	}
}



/* ======= KPI NUMBERS ======= */

.kpi {
	color: $body-color;
	div[class^="col"] {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	.kpi-number {
		font-size: 2rem;
		margin-bottom: 0;
		@include media-breakpoint-up(md) {
			font-size: 2.375rem;
		}
	}
	.kpi-title {
		color: $body-color;
		margin-bottom: 0;
	}
	&.kpi-white {
		color: $white;
		.kpi-title {
			color: $white;
			font-weight: 400;
		}
	}
}



/* ======= BACK TO TOP ======= */

#top {
	position: fixed;
	width: 3rem;
	height: 3rem;
	background: $primary;
	bottom: 1rem;
	right: 1rem;
	border: 1px solid #FFF;
	cursor: pointer;
	z-index: 999;
	transition: .15s linear;
	&:hover {
		opacity: 0.75;
	}
	#top-arrow {
		transform: rotate(45deg);
		border-top: 5px solid #fff;
		border-left: 5px solid #fff;
		border-bottom: none;
		border-right: none;
		width: 1rem;
		height: 1rem;
		margin: 1.2rem auto;
	}
}


// Forms

span.ast {
	color: $primary;
}

#contact-form {
	label {
		font-weight: 600;
		color: $headings-color;
	}
	.form-control {
	    display: block;
	    width: 100%;
	    padding: .75rem 1rem;
	}
}